import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { isEqual } from 'lodash';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import rootStore from './store';
import App from './containers';
import { CONTEXT } from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { store } = rootStore;

const theme = createTheme();
class RootComponent extends Component {
  componentWillMount() {
    let { origin, pathname } = window.location;
    let url = `${origin}${CONTEXT}`;

    if (isEqual(pathname, '/') || !pathname.includes(CONTEXT)) {
      window.location.replace(url);
    }
  }

  render() {
    return (
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Route path={CONTEXT} component={App} />
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    );
  }
}

const RootComponentContainer = RootComponent;

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="en-US" defaultLocale="en-US">
      <RootComponentContainer />
    </IntlProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
